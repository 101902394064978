import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './TrackingPage.scss';
import api from '../Axios/axios';
import { FaPencilAlt, FaArrowRight } from 'react-icons/fa';

const TrackingPage = () => {
  const navigate = useNavigate();
  const [towerCranes, setTowerCranes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [rentals, setRentals] = useState([]);
  const [ownerId, setOwnerId] = useState(null);
  const [userRole, setUserRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [filters, setFilters] = useState({
    serialNumber: '',
    owner: '',
    brand: '',
    model: '',
    height: '',
    tonnage: '',
    status: 'all',
    rentalCompany: '',
    workSite: '',
  });
  const formatCurrency = (amount) => {
    // Değer sayısal değilse sayıya dönüştür
    const numericAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
  
    // Geçersiz veya boş bir değer için kontrol
    if (isNaN(numericAmount) || numericAmount === null || numericAmount === undefined) {
      return '-';
    }
  
    // Geçerli bir sayı için parasal format uygulama
    return new Intl.NumberFormat('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(numericAmount) + ' TL';
  };
  const getUserFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const ownerId = decodedToken.CompanyId;
    const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    setOwnerId(ownerId);
    setUserRole(role);
  };

  const fetchAllRentals = async () => {
    try {
      const rentalResponse = await api.get('/Rental');
      const allRentals = rentalResponse.data.data;
      setRentals(allRentals);
    } catch (error) {
      console.error('Kiralama bilgileri yüklenemedi:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const craneResponse = await api.get('/TowerCrane');
        setTowerCranes(craneResponse.data.data);

        const companyResponse = await api.get('/Company');
        setCompanies(companyResponse.data.data);

        const brandResponse = await api.get('/Brand');
        setBrands(brandResponse.data.data);

        const warehouseResponse = await api.get('/Warehouse');
        setWarehouses(warehouseResponse.data.data);

        await fetchAllRentals();
      } catch (error) {
        console.error('Veriler yüklenemedi:', error);
      }
    };

    getUserFromToken();
    fetchData();
  }, [ownerId]);

  const handleFilterChange = (e, filterName) => {
    setFilters({
      ...filters,
      [filterName]: e.target.value,
    });
  };
  
  function parseTrDateString(dateString) {
    // dateString = "01.12.2025" (dd.MM.yyyy)
    if (!dateString) return null;
  
    const [day, month, year] = dateString.split('.');
    if (!day || !month || !year) return null;
  
    // JavaScript'te ay 0-11 arası (Ocak = 0, Aralık = 11)
    return new Date(Number(year), Number(month) - 1, Number(day));
  }
  function isOverdue(endDateString) {
    if (!endDateString) return false;
    
    // "dd.MM.yyyy" -> Date
    const endDate = parseTrDateString(endDateString);
    if (!endDate) return false; // Geçersizse
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return endDate < today;
  }
  function isNearEndDate(endDateString) {
    if (!endDateString) return false;
    
    const endDate = parseTrDateString(endDateString);
    if (!endDate) return false;
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const diff = endDate - today; // milisaniye farkı
    const daysDiff = diff / (1000 * 60 * 60 * 24);
    return daysDiff >= 0 && daysDiff <= 30;
  }
  
  function formatTrDate(isoDateString) {
    if (!isoDateString) return '-'; // Boş ise
    const dateObj = new Date(isoDateString);
    
    if (isNaN(dateObj.getTime())) return '-'; // Geçersiz tarih
    
    const day = dateObj.getDate();            // 1-31
    const month = dateObj.getMonth() + 1;     // 0-11 => 1-12
    const year = dateObj.getFullYear();
    
    const dayStr = day < 10 ? '0' + day : '' + day;
    const monthStr = month < 10 ? '0' + month : '' + month;
    
    return `${dayStr}.${monthStr}.${year}`;
  }
  const getCompanyName = (ownerId) => {
    const company = companies.find((company) => company.id === ownerId);
    return company ? company.name : '-';
  };

  const getBrandName = (brandId) => {
    const brand = brands.find((brand) => brand.id === brandId);
    return brand ? brand.name : '-';
  };

  const getRentalDetails = (crane) => {
    // Sadece isActive olan kiralamayı bul
    const activeRental = rentals.find((rental) => rental.towerCraneId === crane.id && rental.isActive);

    if (activeRental) {
      return {
        startDate: formatTrDate(activeRental.rentalStartDate), 
        endDate: formatTrDate(activeRental.rentalEndDate),
        fee: activeRental.rent ? `${activeRental.rent} TL` : '-',
        rentalDuration: activeRental.rentalPeriod + activeRental.extraPeriod  || '-',
        workSite: activeRental.workSite || '-',
        status: 'Inused',
        rentalCompany: activeRental.rentingCompany || '-',
        rentalId: activeRental.id,
      };
    }

    // Eğer aktif kiralama yoksa
    return {
      startDate: '-',
      endDate: '-',
      fee: '-',
      rentalDuration: '-',
      workSite: crane.warehouse || '-',
      status: 'Active',
      rentalCompany: '-',
      rentalId: null,
    };
  };


  const filteredTowerCranes = towerCranes
    .filter((crane) => {
      if (userRole === 'Company' && crane.ownerId !== ownerId) return false;

      const rentalDetails = getRentalDetails(crane);
      //  console.log(crane);
      const matchesSerialNumber = !filters.serialNumber || crane.serialNo.toLowerCase().includes(filters.serialNumber.toLowerCase());
      const matchesOwner = !filters.owner || crane.ownerId === filters.owner;
      const matchesBrand = !filters.brand || crane.brandId.toString() === filters.brand;
      const matchesModel = !filters.model || crane.model === filters.model;
      const matchesHeight = !filters.height || crane.height.toString() === filters.height;
      const matchesTonnage = !filters.tonnage || crane.maxTonnage.toString() === filters.tonnage;
      const matchesStatus = filters.status === 'all' || rentalDetails.status === filters.status;
      const matchesRentalCompany = !filters.rentalCompany || rentalDetails.rentalCompany === filters.rentalCompany;
      const matchesWorkSite = !filters.workSite || rentalDetails.workSite === filters.workSite;

      return (
        matchesSerialNumber &&
        matchesOwner &&
        matchesBrand &&
        matchesModel &&
        matchesHeight &&
        matchesTonnage &&
        matchesStatus &&
        matchesRentalCompany &&
        matchesWorkSite
      );
    })
    .sort((a, b) => {
      const rentalA = getRentalDetails(a);
      const rentalB = getRentalDetails(b);

      if (rentalA.status === 'Active' && rentalB.status !== 'Active') return -1;
      if (rentalA.status !== 'Active' && rentalB.status === 'Active') return 1;

      const isCloseToEndDateA = isNearEndDate(rentalA.endDate);
      const isCloseToEndDateB = isNearEndDate(rentalB.endDate);
      if (isCloseToEndDateA && !isCloseToEndDateB) return 1;
      if (!isCloseToEndDateA && isCloseToEndDateB) return -1;

      return 0;
    });

  const uniqueBrands = Array.from(new Set(filteredTowerCranes.map((crane) => crane.brandId)));
  const uniqueOwners = Array.from(new Set(filteredTowerCranes.map((crane) => crane.ownerId)));
  const uniqueModels = Array.from(new Set(filteredTowerCranes.map((crane) => crane.model)));
  const uniqueHeights = Array.from(new Set(filteredTowerCranes.map((crane) => crane.height)));
  const uniqueTonnages = Array.from(new Set(filteredTowerCranes.map((crane) => crane.maxTonnage)));
  const uniqueRentalCompanies = Array.from(new Set(filteredTowerCranes.map((crane) => getRentalDetails(crane).rentalCompany)));
  const uniqueWorkSites = Array.from(new Set(filteredTowerCranes.map((crane) => getRentalDetails(crane).workSite)));

  const handleEdit = (rentalId) => {
    navigate(`/rentaledit/${rentalId}`);
  };

  const handleRentalPage = (craneId) => {
    navigate(`/rentalpage/${craneId}`);
  };

  // Duruma göre satır sınıfı
  function getStatusClass(status, overdue, nearEnd) {
    // status = 'Inused' (kirada) / 'Active' (boşta)
    if (status === 'Inused') {
      if (overdue) {
        return 'row-overdue';   // Süresi geçmiş
      }
      if (nearEnd) {
        return 'row-warning';   // Süresi yaklaşan
      }
      return 'row-used';        // Kirada (normal)
    }
    return 'row-active';        // Boşta
  }


// Sıralama Mantığı
const sortedTowerCranes = [...filteredTowerCranes].sort((a, b) => {
  const rentalA = getRentalDetails(a, rentals);
  const rentalB = getRentalDetails(b, rentals);

  // 1) Boşta (Active) vs Kirada (Inused)
  if (rentalA.status === 'Active' && rentalB.status === 'Inused') {
    return -1; // 'A' üstte
  }
  if (rentalA.status === 'Inused' && rentalB.status === 'Active') {
    return 1;  // 'B' üstte
  }
  
  // Eğer ikisi de 'Active' ise
  if (rentalA.status === 'Active' && rentalB.status === 'Active') {
    return 0; // İkisi de boşta, olduğu gibi kalabilir
  }

  // Şu noktada ikisi de 'Inused' (Kirada) demektir:
  // 2) Süresi bitmiş (Overdue) kontrolü
  const overdueA = isOverdue(rentalA.endDate); // true/false
  const overdueB = isOverdue(rentalB.endDate);
  
  if (overdueA && !overdueB) return -1; // A üstte
  if (!overdueA && overdueB) return 1;  // B üstte

  // 3) Bitiş tarihi en yakın olan üste (tarih küçük olan)
  const dateA = parseTrDateString(rentalA.endDate);
  const dateB = parseTrDateString(rentalB.endDate);

  // Tarihi olmayanlar (belki '-') en sona gitsin
  if (!dateA && dateB) return 1;
  if (dateA && !dateB) return -1;
  if (!dateA && !dateB) return 0;

  // İkisi de geçerli tarihse küçük tarih (erken bitiş) üstte
  return dateA - dateB;
});

  return (
    <div className="tracking-page">
      <Navbar />
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Seri Numarası</th>
              <th>Kule Vinç Sahibi</th>
              <th>Marka</th>
              <th>Model</th>
              <th>Yükseklik</th>
              <th>Tonaj</th>
              <th>Durum</th>
              <th>Kira Başlangıç Tarihi</th>
              <th>Kira Bitiş Tarihi</th>
              <th>Ücret</th>
              <th>Kira Süresi (Ay)</th>
              <th>Kiralanan Firma</th>
              <th>Şantiye/Depo</th>
              <th>İşlemler</th>
            </tr>
            <tr>
              {/* Filtreler */}
              <th>
                <div className="input-container">
                  <input
                    type="text"
                    value={filters.serialNumber}
                    placeholder="Seri No Ara"
                    onChange={(e) => handleFilterChange(e, 'serialNumber')}
                  />
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.owner}
                    onChange={(e) => handleFilterChange(e, 'owner')}
                  >
                    <option value="">Tümü</option>
                    {uniqueOwners.map((owner, index) => (
                      <option key={index} value={owner}>
                        {getCompanyName(owner)}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.brand}
                    onChange={(e) => handleFilterChange(e, 'brand')}
                  >
                    <option value="">Tümü</option>
                    {uniqueBrands.map((brand, index) => (
                      <option key={index} value={brand}>
                        {getBrandName(brand)}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.model}
                    onChange={(e) => handleFilterChange(e, 'model')}
                  >
                    <option value="">Tümü</option>
                    {uniqueModels.map((model, index) => (
                      <option key={index} value={model}>
                        {model}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.height}
                    onChange={(e) => handleFilterChange(e, 'height')}
                  >
                    <option value="">Tümü</option>
                    {uniqueHeights.map((height, index) => (
                      <option key={index} value={height}>
                        {height}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.tonnage}
                    onChange={(e) => handleFilterChange(e, 'tonnage')}
                  >
                    <option value="">Tümü</option>
                    {uniqueTonnages.map((tonnage, index) => (
                      <option key={index} value={tonnage}>
                        {tonnage}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.status}
                    onChange={(e) => handleFilterChange(e, 'status')}
                  >
                    <option value="all">Tümü</option>
                    <option value="Active">Boşta</option>
                    <option value="Inused">Kirada</option>
                  </select>
                </div>
              </th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.rentalCompany}
                    onChange={(e) => handleFilterChange(e, 'rentalCompany')}
                  >
                    <option value="">Tümü</option>
                    {uniqueRentalCompanies.map((company, index) => (
                      <option key={index} value={company}>
                        {company}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th>
                <div className="select-container">
                  <select
                    value={filters.workSite}
                    onChange={(e) => handleFilterChange(e, 'workSite')}
                  >
                    <option value="">Tümü</option>
                    {uniqueWorkSites.map((site, index) => (
                      <option key={index} value={site}>
                        {site}
                      </option>
                    ))}
                  </select>
                </div>
              </th>
              <th></th>

            </tr>
          </thead>
          <tbody>
            {sortedTowerCranes.map((crane, index) => {
              const rentalDetails = getRentalDetails(crane);
              const isCloseToEndDate = isNearEndDate(rentalDetails.endDate);
              const overdue = isOverdue(rentalDetails.endDate);
              const rowClass = getStatusClass(rentalDetails.status,overdue, isCloseToEndDate);

              //  console.log(crane.serialNo, rentalDetails);
              return (
                <tr key={crane.id} className={rowClass}>
                  <td>{crane.serialNo}</td>
                  <td>{getCompanyName(crane.ownerId)}</td>
                  <td>{getBrandName(crane.brandId)}</td>
                  <td>{crane.model || '-'}</td>
                  <td>{crane.height}m</td>
                  <td>{crane.maxTonnage}T</td>
                  <td>  
                  {rentalDetails.status === 'Inused' ? 'Kirada' : 'Boşta'}</td>
                  <td>{rentalDetails.startDate}</td>
                  <td>{rentalDetails.endDate}</td>
                  <td>{formatCurrency(rentalDetails.fee) }</td>
                  <td>{rentalDetails.rentalDuration}</td>
                  <td>{rentalDetails.rentalCompany}</td>
                  <td>{rentalDetails.workSite || '-'}</td>
                  <td>
                    <div className="btn-container">
                      {userRole === 'Owner' && rentalDetails.rentalId && (
                        <button className="small-btn edit-btn" onClick={() => handleEdit(rentalDetails.rentalId)}>
                          <FaPencilAlt />
                        </button>
                      )}
                      <button className="small-btn details-btn" onClick={() => handleRentalPage(crane.id)}>
                        <FaArrowRight />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrackingPage;
